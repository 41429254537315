import * as C from '../Constants';

export class DataHelper {
  static UserIp = null;
  static UserCountry = null;
  static UserCity = null;
  static initiated = false;

  static getJSON = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        callback(null, xhr.response);
      } else {
        callback(status, xhr.response);
      }
    };
    xhr.send();
  };

  static LocationInitialization = () => {
    if (!this.initiated) {
      this.initiated = true;
      this.getJSON('https://json.geoiplookup.io/', function (err, data) {
        if (err !== null) {
          console.log('Something went wrong: ' + err);
        } else {
          DataHelper.UserIp = data.ip;
          DataHelper.UserCountry = data.country_name;
          DataHelper.UserCity = data.city;
          // console.log('ini done');
        }
      });
    }
  };

  static GetRequestOptions = (question) => {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        testAreaId: question.TestAreaId,
        questionId: question.Id,
        isCorrect: question.IsCorrect,
        userCountry: this.UserCountry,
        userCity: this.UserCity,
        userIP: this.UserIp,
      }),
    };
  };

  static SendResult = (question, errorCallback) => {
    fetch(`${C.API}/ppl/result`, this.GetRequestOptions(question))
      .then(async (response) => {
        if (!response.ok) return Promise.reject(response.status);
      })
      .catch((error) => errorCallback(error));
  };
}
