import React, { Component } from 'react';
import AreaSelector from './AreaSelector/AreaSelector';

class ManagerPanel extends Component {
  state = { questionsCount: 30, areaId: 0, onlyWrongAndNew: true };
  render() {
    return (
      <React.Fragment>
        <div className='container my-2'>
          <div className='row'>
            <div className='col-2'></div>
            <div className='col-1'>
              <select
                className='custom-select font-sm'
                onChange={this.handleNumberSelection}
                value={this.state.questionsCount}>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option defaultValue value='30'>
                  30
                </option>
                <option value='50'>50</option>
              </select>
            </div>
            <div className='col-4'>
              <AreaSelector
                correct={this.props.correct}
                onAreaSelection={(areaId) => this.handleAreaSelection(areaId)}
              />
            </div>
            <div className='col-2'>
              <div className='form-check'>
                <input
                  className='form-check-input zero-outline'
                  type='checkbox'
                  id='cbOnlyWrongAndNew'
                  checked={this.state.onlyWrongAndNew ? 'checked' : ''}
                  onChange={() => this.handleQuestionsTypeChange()}
                />
                <label
                  className='form-check-label font-sm'
                  htmlFor='cbOnlyWrongAndNew'>
                  Only new and incorrectly
                </label>
              </div>
            </div>
            <div className='col-1'>
              <button
                className='btn btn-sm btn-outline-success font-sm'
                onClick={() => this.handleReload(false)}>
                Reload
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  handleNumberSelection = (event) =>
    this.setState({ questionsCount: event.target.value });
  handleAreaSelection = (areaId) =>
    this.setState({ areaId: areaId }, () => this.handleReload(true));
  handleQuestionsTypeChange = () =>
    this.setState({ onlyWrongAndNew: !this.state.onlyWrongAndNew });
  handleReload = (areaChanged) => {
    if (this.state.areaId === 0) return;
    this.props.onReload(
      this.state.areaId,
      this.state.questionsCount,
      this.state.onlyWrongAndNew,
      areaChanged
    );
  };
}

export default ManagerPanel;
