import React, { Component } from 'react';

class Question extends Component {
  componentDidMount() {
    this.setQuestionHtml();
    this.setExplanationHtml();
  }
  componentWillUnmount() {
    // this.clearBodyHtml();
  }
  componentDidUpdate() {
    // this.clearBodyHtml();
    this.setQuestionHtml();
    this.setExplanationHtml();
    if (!this.props.question.IsAnswered)
      document.getElementById('nav-question-tab').click();
  }
  render() {
    const { question } = this.props;
    return (
      <React.Fragment>
        <nav>
          <div className='nav nav-tabs' id='nav-tab' role='tablist'>
            <button
              className='nav-link active'
              id='nav-question-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-question'
              type='button'
              role='tab'
              aria-controls='nav-question'
              aria-selected='true'>
              question
            </button>
            <button
              className='nav-link'
              id='nav-explanation-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-explanation'
              type='button'
              role='tab'
              aria-controls='nav-explanation'
              aria-selected='false'>
              explanation
            </button>
          </div>
        </nav>
        <div className='tab-content' id='nav-tabContent'>
          <div
            className='tab-pane fade show active'
            id='nav-question'
            role='tabpanel'
            aria-labelledby='nav-question-tab'>
            <h5 className='text-start mt-3' id={this.getQuestionContainerId()}>
              {question.QuestionText}
            </h5>
            <table className='table table-hover'>
              <tbody>
                <tr
                  onClick={() => this.handleClick(0)}
                  className={this.getAnswerClass(0)}>
                  <td>A</td>
                  <td className='text-start'>{question.OptionA}</td>
                </tr>
                <tr
                  onClick={() => this.handleClick(1)}
                  className={this.getAnswerClass(1)}>
                  <td>B</td>
                  <td className='text-start'>{question.OptionB}</td>
                </tr>
                <tr
                  onClick={() => this.handleClick(2)}
                  className={this.getAnswerClass(2)}>
                  <td>C</td>
                  <td className='text-start'>{question.OptionC}</td>
                </tr>
                <tr
                  onClick={() => this.handleClick(3)}
                  className={this.getAnswerClass(3)}>
                  <td>D</td>
                  <td className='text-start'>{question.OptionD}</td>
                </tr>
              </tbody>
            </table>
            <h6 className='text-start font-sm'>
              {question.Id} - {question.TestId}
            </h6>
          </div>
          <div
            className='tab-pane fade'
            id='nav-explanation'
            role='tabpanel'
            aria-labelledby='nav-explanation-tab'>
            <p className='text-start m-3' id={this.getExplanationContainerId()}>
              {question.Explanation}
            </p>
            <p className='text-start m-3'>
              {question.JpegArray?.map((jpg, index) => (
                <img src={jpg} alt='' key={index} className='col-12'></img>
              ))}
              {question.PngArray?.map((png, index) => (
                <img src={png} alt='' key={index} className='col-12'></img>
              ))}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
  getQuestionContainerId = () => `q${this.props.question.Id}`;
  getExplanationContainerId = () => `e${this.props.question.Id}`;
  setQuestionHtml = () => {
    let html = this.props.question.QuestionTextHtml;
    if (html)
      document.getElementById(this.getQuestionContainerId()).innerHTML = html;
  };
  setExplanationHtml = () => {
    let html = this.props.question.ExplanationHtml;
    if (html)
      document.getElementById(this.getExplanationContainerId()).innerHTML =
        html;
  };
  handleClick = (option) => {
    if (this.props.question.IsAnswered) return;

    if (this.props.question.CorrectAnswer === option)
      this.props.onCorrectAnswer();
    else this.props.onWrongAnswer(option);
  };
  getAnswerClass = (answer) => {
    let question = this.props.question;
    if (question.IsAnswered) {
      if (question.CorrectAnswer === answer) return 'table-success';
      if (!question.IsCorrect && question.WrongAnswer === answer)
        return 'table-danger';
    }
  };
}

export default Question;
