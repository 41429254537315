import React, { Component } from 'react';
import { ChevronRightIcon } from '@primer/octicons-react';

class NavNext extends Component {
  render() {
    return (
      <div
        className='navi'
        id='navi-next'
        title='next'
        onClick={this.props.onClick}>
        <div className='highlight'>
          <ChevronRightIcon size={24} />
        </div>
      </div>
    );
  }
}

export default NavNext;
