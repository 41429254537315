import React, { Component } from 'react';
import './App.css';
import ManagerPanel from './components/ManagerPanel/ManagerPanel';
import Test from './components/Test/Test';
import { DataHelper } from './helpers/DataHelper';

class App extends Component {
  state = { areaId: null, testNumber: 1, correct: 0 };
  componentDidMount() {
    DataHelper.LocationInitialization();
  }
  render() {
    return (
      <div className='App'>
        <div className='alert alert-info p-0 mb-1' role='alert'>
          чтоб с Кристюшинкой летать - сдай все тесты ты на 5!
        </div>
        <ManagerPanel
          correct={this.state.correct}
          onReload={this.handleReload}
        />
        {this.state.areaId != null ? (
          <Test
            areaId={this.state.areaId}
            take={this.state.take}
            onlyWrongAndNew={this.state.onlyWrongAndNew}
            testNumber={this.state.testNumber}
            onCorrectAnswer={this.handleCorrect}
          />
        ) : null}
      </div>
    );
  }
  handleReload = (areaId, take, onlyWrongAndNew, areaChanged) =>
    this.setState({
      areaId: areaId,
      take: take,
      correct: areaChanged ? 0 : this.state.correct,
      onlyWrongAndNew: onlyWrongAndNew,
      testNumber: this.state.testNumber + 1,
    });
  handleCorrect = () =>
    this.setState({
      correct: this.state.correct + 1,
    });
}

export default App;
