import React, { Component } from 'react';

class QuestionsGrid extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.questions?.map((question, index) => (
          <button
            className={this.getClasses(question, index)}
            key={index}
            onClick={() => this.props.onClick(index)}>
            {index + 1}
          </button>
        ))}
      </React.Fragment>
    );
  }

  getClasses = (question, index) =>
    `btn btn-sm btn-q ${
      question.IsAnswered
        ? question.IsCorrect
          ? 'btn-success'
          : 'btn-danger'
        : this.props.currentQuestionIndex === index
        ? 'btn-secondary'
        : 'btn-outline-secondary'
    } me-1 mb-1`;
}

export default QuestionsGrid;
