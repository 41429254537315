import React, { Component } from 'react';
import { ChevronLeftIcon } from '@primer/octicons-react';

class NavPrev extends Component {
  render() {
    return (
      <div
        className='navi'
        id='navi-prev'
        title='prev'
        onClick={this.props.onClick}>
        <div className='highlight'>
          <ChevronLeftIcon size={24} />
        </div>
      </div>
    );
  }
}

export default NavPrev;
