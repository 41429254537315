import React, { Component } from 'react';
import { DataHelper } from '../../helpers/DataHelper';
import { ObjectsHelper } from '../../helpers/ObjectsHelper';
import * as C from './../../Constants';
import NavNext from './NavNext/NavNext';
import NavPrev from './NavPrev/NavPrev';
import Question from './Question/Question';
import QuestionsGrid from './QuestionGrid/QuestionGrid';

class Test extends Component {
  state = { isLoaded: false, currentQuestionIndex: 0 };
  componentDidMount() {
    this.dataFetch();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.areaId !== prevProps.areaId ||
      this.props.take !== prevProps.take ||
      this.props.onlyWrongAndNew !== prevProps.onlyWrongAndNew ||
      this.props.testNumber !== prevProps.testNumber
    )
      this.dataFetch();
  }
  dataFetch() {
    fetch(
      `${C.API}/ppl/questions?areaId=${this.props.areaId}&take=${this.props.take}&onlyWrongAndNew=${this.props.onlyWrongAndNew}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result || result.Message) {
            this.setState({ is404: true });
            return;
          }
          ObjectsHelper.EnrichQuestions(result);
          this.setState({
            isLoaded: true,
            questions: result,
            currentQuestionIndex: 0,
          });
        },
        (error) => {
          console.log('error');
          this.setState({ isLoaded: true, error });
        }
      );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.error ? (
          <div className='alert alert-danger' role='alert'>
            {this.state.error}
          </div>
        ) : null}
        <div className='container'>
          <div className='row'>
            <div className='col-1 p-1'>
              <NavPrev onClick={this.handlePrev} />
            </div>
            <div className='col'>
              {this.state.isLoaded && this.state.questions.length > 0 ? (
                <Question
                  question={
                    this.state.questions[this.state.currentQuestionIndex]
                  }
                  onCorrectAnswer={this.handleCorrect}
                  onWrongAnswer={(answer) => this.handleWrong(answer)}
                />
              ) : (
                <div className='mx-3 mt-2 mb-1'>Loading...</div>
              )}
            </div>
            <div className='col-1 p-1'>
              <NavNext onClick={this.handleNext} />
            </div>
            <div className='col-2 p-1'>
              {this.state.isLoaded && this.state.questions.length > 0 ? (
                <QuestionsGrid
                  questions={this.state.questions}
                  currentQuestionIndex={this.state.currentQuestionIndex}
                  testNumber={this.props.testNumber}
                  onClick={(index) => this.handleGridClick(index)}
                />
              ) : (
                <div className='mx-3 mt-2 mb-1'>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  handleNext = () =>
    this.state.currentQuestionIndex < this.state.questions.length - 1
      ? this.setState({
          currentQuestionIndex: this.state.currentQuestionIndex + 1,
          error: null,
        })
      : null;
  handlePrev = () =>
    this.state.currentQuestionIndex > 0
      ? this.setState({
          currentQuestionIndex: this.state.currentQuestionIndex - 1,
          error: null,
        })
      : null;
  handleCorrect = () => {
    let questions = [...this.state.questions];
    let question = questions[this.state.currentQuestionIndex];
    question.IsAnswered = true;
    question.IsCorrect = true;

    this.setState({ questions });
    this.handleNext();

    DataHelper.SendResult(question, this.handleError);
    this.props.onCorrectAnswer();
  };
  handleWrong = (answer) => {
    let questions = [...this.state.questions];
    let question = questions[this.state.currentQuestionIndex];
    question.IsAnswered = true;
    question.IsCorrect = false;
    question.WrongAnswer = answer;

    this.setState({ questions });

    DataHelper.SendResult(question, this.handleError);
  };
  handleGridClick = (index) =>
    this.setState({
      currentQuestionIndex: index,
      error: null,
    });
  handleError = (error) => this.setState({ error: error.message });
}

export default Test;
